import React, { FC } from 'react';

import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';

import useMedia from 'hooks/useMedia';
import FormInputType from 'utils/types/FormInput';

import { Inputs } from './Inputs/Inputs';
import {
  StyledContactFormContainer,
  StyledContactForm,
  StyledInputColumn,
  StyledTextWrapper,
  StyledTextColumn,
  StyledBackground,
  StyledContainer
} from './ContactForm.styles';
import { Shapes } from './Shapes/Shapes';

export type ContactFormProps = {
  title: string;
  description: string;
  contactFormInputs: Array<FormInputType>;
  buttonText: string;
};

export const ContactForm: FC<ContactFormProps> = ({ title, description, contactFormInputs, buttonText }) => {
  const { isMobile } = useMedia();

  return (
    <StyledContainer>
      {!isMobile && <StyledBackground />}
      <StyledContactFormContainer>
        <StyledTextColumn>
          <Container>
            <Typography variant="h2" renderAs="h2" weight="700">
              {title}
            </Typography>
            <StyledTextWrapper>
              <Typography variant="body2" weight="400">
                {description}
              </Typography>
            </StyledTextWrapper>
          </Container>
        </StyledTextColumn>
        <StyledInputColumn>
          <StyledContactForm>
            <Shapes />
            <Container>
              <Inputs contactFormInputs={contactFormInputs} buttonText={buttonText} variant="onViolet" />
            </Container>
          </StyledContactForm>
        </StyledInputColumn>
      </StyledContactFormContainer>
    </StyledContainer>
  );
};
