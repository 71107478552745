import styled from 'styled-components';
import { mq } from 'theme/media';

export const StyledContainer = styled.div`
  display: none;
  position: absolute;
  width: calc(${({ theme }) => theme.sizes.layoutWidth} / 2);
  height: 100%;
  right: 0;
  z-index: 1;

  ${mq.xsLarge`
    display: block;
  `}
`;
