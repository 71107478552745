import React, { FC } from 'react';

import { Shape } from 'components/atoms/Shape/Shape';
import { StyledContainer } from './Shapes.styles';

export const Shapes: FC = () => (
  <StyledContainer>
    <Shape
      src="/shapes/form_right_up_corner.png"
      width={136}
      height={200}
      position="absolute"
      top="-80px"
      right="-100px"
    />
    <Shape
      src="/shapes/form_left_down_corner.png"
      width={180}
      height={144}
      position="absolute"
      bottom="120px"
      left="-380px"
    />
  </StyledContainer>
);
